<template>
  <a-layout id="layout">
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      style="background-color: #ffffff"
    >
      <div class="logo" @click="toHome()">
        <img src="~@/assets/img/title.png" alt="" v-if="!collapsed" />
        <img
          src="~@/assets/img/title2.png"
          alt=""
          v-else
          style="width: 24px; height: 24px"
        />
      </div>
      <Menus :activeKey="activeKey" @submenuNumber="submenuNumber"></Menus>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        style="
          position: relative;
          background: #ffffff;
          padding: 0;
          display: flex;
          justify-content: space-between;
          margin-left: 5px;
        "
      >
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />

        <div class="header-right">
          <div class="company">
            <img src="~@/assets/img/company.png" alt="" />
            <span>深圳易伙科技有限责任公司</span>
          </div>
          <div class="company">
            <img :src="manageInfo.picture" v-if="manageInfo.picture" />
            <img src="~@/assets/img/company.png" v-else />
            <span>{{ manageInfo.nickname }}</span>
          </div>
          <div class="user">
            <a-icon type="logout" />
            <span
              style="display: inline-block; margin: 0 8px; cursor: pointer"
              @click="logout"
              >退出</span
            >
          </div>
        </div>
      </a-layout-header>
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <a-breadcrumb>
          <a-breadcrumb-item v-for="item in activeRoute" :key="item">
            <a>{{ item }}</a>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <!-- <Tabs @sendKey="sendKey"></Tabs> -->
      <a-layout-content class="content">
        <!-- <keep-alive exclude="ModelDetail"> -->
        <router-view />
        <!-- <router-view></router-view> -->

        <!-- </keep-alive> -->
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Menus from './Menus.vue'
import Tabs from './Tabs.vue'
// import routesConfig from '@/router/router.js'

export default {
  name: 'Layout',
  components: {
    Menus,
    Tabs,
  },
  data() {
    return {
      collapsed: false,
      activeKey: undefined,
      isAlive: true,
      activeRoute: ['首页', '设备管理'],
      subMenu: null,
      manageInfo: {},
    }
  },
  computed: {
    ...mapState(['menuLists']),
  },
  methods: {
    sendKey(activeKey) {
      this.activeKey = activeKey
    },
    logout() {
      sessionStorage.clear()
      localStorage.clear()
      const homePage = window.location.href
      const authUrl =
        process.env.VUE_APP_AUTH_API +
        `/sessions/logout?post_logout_redirect_uri=${homePage}`
      console.log(authUrl, 'authurl')
      window.open(authUrl)
      // sessionStorage.removeItem('token')

      // this.$api._logout().then((res) => {
      //   console.log(res)
      // })
      // this.$router.push({ path: '/login' })
    },
    // 搜索事件
    onSearch(value, event) {},
    // 重载
    reload() {
      this.isAlive = false
      this.$nextTick(() => {
        this.isAlive = true
      })
    },
    // 路由地址名替换方法
    rep(arr) {
      // console.log(arr, '菜单')
      const replaceMap = {
        layout: '首页',
        userManage: '用户管理',
        userStatistics: '用户统计',
        userList: '用户列表',
        resource: '资源管理',
        resourceInitialize: '资源初始化',
        resourceUse: '资源使用',
        notification: '消息推送',
        systemNotification: '系统消息推送',
        businessNotification: '业务消息推送',
        application: '应用市场',
        applicationAudit: '应用模板审核',
        applicationManage: '应用模板管理',
        systemManage: '系统管理',
        appManage: 'APP管理',
        applicationRegister: '登记高级业务',
        advertisementPut: '广告投放',
        resourceIntegration: '资源封装',
        apiList: 'API列表',
        tenantManage: '租户管理',
        tenantList: '租户列表',
        wechatMallManage: '微信特约商户管理',
        tenantDetail: '租户详情',
        modifyAuth: '企业认证',
        gatewayRouting: '网关路由',
        dataCenter: '数据中心',
        dataManagement: '数据治理',
        dataDictionary: '数据字典',
        dataTask: '任务调度',
        taskLog: '任务调度日志',
        dataProcessing: '数据处理',
        processingLog: '数据处理日志',
      }

      const mappedArr = arr.map((item) => {
        return replaceMap[item] || item
      })

      return mappedArr
    },
    toHome() {
      this.$router.push({
        path: '/layout',
      })
    },
    submenuNumber(value) {
      this.subMenu = value
      // console.log(this.subMenu)
    },
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  mounted() {
    // console.log(this.$store.getters.roles)
  },
  async created() {
    /* 刷新后面包屑为空的问题 */
    this.menuLists.forEach((item) => {
      item.children.forEach((children) => {
        if (children.path === this.$route.path) {
          this.subMenu = children.parentId
        }
      })
    })
    /* 获取登录的管理员信息 */
    // try {
    // } catch (err) {}
    let [err, res] = await this.$api.getCurrentUserTenantInfo().then(
      (res) => [null, res],
      (err) => [err, null]
    )
    if (err != null) {
      console.log(err.message)
    } else {
      this.manageInfo = res.data
      console.log(res, 'manageInfo')
    }
  },
  watch: {
    $route: {
      handler(newRouter, oldRouter) {
        // console.log('当前路由', newRouter)
        // 实现头部面包屑随路由变化的动态效果
        // console.log(newRouter)
        this.activeRoute = newRouter.path.split('/')
        this.activeRoute.shift()
        // console.log(this.activeRoute)

        this.activeRoute = this.rep(this.activeRoute)
        // console.log(this.subMenu, 'watch')
      },
      deep: true,
      immediate: true,
    },
    subMenu: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeRoute = this.$route.path.split('/')
        this.activeRoute.shift()
        // console.log(this.activeRoute)

        this.activeRoute = this.rep(this.activeRoute)
      },
    },
  },
}
</script>

<style lang="less" scoped>
#layout {
  height: 100%;
  min-width: 1200px;
}
#layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#layout .trigger:hover {
  color: #1890ff;
}

.logo {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ececec;
  position: relative;
  cursor: pointer;
  img {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 57%;
    height: 18px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
    font-size: 20px;
  }
}

.ant-layout-header {
  /deep/ .ant-input {
    background-color: #f0f2f5 !important;
  }
}
.search {
  position: absolute;
  left: 64px;
  width: 400px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
}
/deep/ .ant-layout-content {
  height: 100%;
  overflow: hidden;
  padding: 0 10px 0 10px !important;
  margin: 0;
}

.breadcrumb {
  height: 40px;
  width: 100%;
  background-color: #ffffff;
  margin-left: 5px;
  .ant-breadcrumb {
    margin-left: 20px;
  }
}

.header-right {
  display: flex;
  margin-right: 20px;
  img {
    display: flex;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    margin-left: 10px;
  }
  .company,
  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }
}

.content {
  margin: 8px;
  padding: 0 24px;
  background: #fff;
  min-height: 280px;
  height: calc(100vh -100px);
  overflow: scroll;
}
</style>
