<template>
  <div id="topNav">
    <div class="left">
      <a-tabs
        v-model="activeKey"
        type="editable-card"
        @edit="onEdit"
        @change="changeTabs"
        :hideAdd="true"
      >
        <a-tab-pane
          v-for="pane in panes"
          :key="pane.path"
          :tab="pane.menuName"
          :closable="pane.closable"
        >
        </a-tab-pane>
      </a-tabs>
    </div>

    <div class="right">
      <a-dropdown>
        <a-button class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          操作 <a-icon type="down" />
        </a-button>
        <a-menu slot="overlay" @click="onClick">
          <a-menu-item key="1"> 关闭当前 </a-menu-item>
          <a-menu-item key="2"> 关闭其他 </a-menu-item>
          <a-menu-item key="3"> 关闭全部</a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Tabs',
  data() {
    const panes = [
      { menuName: '欢迎页', path: '/layout/welcome', closable: false },
    ]
    return {
      activeKey: '/layout/welcome',
      panes,
      newTabIndex: 0,
    }
  },
  computed: {
    ...mapState(['menuLists']),
  },
  watch: {
    $route: {
      handler(newRouter, oldRouter) {
        for (let pane of this.panes) {
          if (pane.path === newRouter.path) {
            this.activeKey = newRouter.path
            return true
          }
        }
        this.getItemList(this.menuLists, newRouter.path)
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    getItemList(data, key) {
      for (let item of data) {
        if (item.path === key) {
          this.panes.push(item)
          this.activeKey = item.path
          return true
        }
        if (item.children && item.children.length > 0) {
          if (this.getItemList(item.children, key)) {
            return true
          }
        }
      }
    },
    onEdit(targetKey, action) {
      this[action](targetKey)
    },
    remove(targetKey) {
      let activeKey = this.activeKey
      let lastIndex
      this.panes.forEach((pane, i) => {
        if (pane.path === targetKey) {
          lastIndex = i - 1
        }
      })
      const panes = this.panes.filter((pane) => pane.path !== targetKey)
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].path
        } else {
          activeKey = panes[0].path
        }
      }
      this.panes = panes
      this.activeKey = activeKey
      if (this.activeKey !== targetKey) {
        this.$emit('sendKey', this.activeKey)
      }
    },
    changeTabs(activeKey) {
      this.$emit('sendKey', activeKey)
    },
    onClick({ key }) {
      if (key == 1) {
        if (this.activeKey !== '/layout/welcome') {
          this.remove(this.activeKey)
        }
      } else if (key == 2) {
        let arrKeys = this.panes.filter((item) => {
          if (item.path !== '/layout/welcome' && item.path !== this.activeKey) {
            return true
          }
        })
        if (arrKeys.length > 0) {
          for (let item of arrKeys) {
            this.remove(item.path)
          }
        }
      } else {
        let arrKeys = this.panes.filter((item) => {
          if (item.path !== '/layout/welcome') {
            return true
          }
        })
        if (arrKeys.length > 0) {
          for (let item of arrKeys) {
            this.remove(item.path)
          }
        }
      }
    },
  },
}
</script>



<style scoped>
#topNav {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #E8E8E8;
}
.left {
  width: calc(100% - 100px);
}
.right {
  width: 100px;
  line-height: 40px;
  text-align: center;
}
 /deep/ .ant-tabs{
  height: 40px;
}
</style>
