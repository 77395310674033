<template>
  <div class="menus">
    <a-menu
      mode="inline"
      theme="light"
      :inline-collapsed="collapsed"
      @click="menuItemChick"
      @openChange="openChange"
      :selectedKeys="selectedKeys"
      :openKeys="openKeys"
    >
      <!-- 一级菜单 -->
      <template v-for="item in menu">
        <!-- 如果有子菜单 -->
        <template v-if="item.children && item.children.length > 0">
          <a-sub-menu :key="item.path">
            <span slot="title">
              <a-icon :type="item.meta.icon ? item.meta.icon : 'appstore'" />
              <span>{{ item.meta.title }}</span>
            </span>
            <!-- 二级菜单 -->
            <template
              v-for="child in item.children.filter((item) => !item.hidden)"
            >
              <template>
                <a-menu-item :key="child.path">
                  <router-link :to="{ path: child.path }">
                    {{ child.meta.title }}
                  </router-link>
                </a-menu-item>
              </template>
            </template>
          </a-sub-menu>
        </template>
        <!-- 没有子菜单 -->
        <template v-else>
          <a-menu-item :key="item.path">
            <router-link :to="{ path: item.path }">
              <span>{{ item.meta.title }}</span>
            </router-link>
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { menu } from '@/router/router.js'

export default {
  // props: ['activeKey'],
  data() {
    return {
      collapsed: false,
      selectedKeys: ['UserList'] /* 选中菜单项 */,
      openKeys: ['2'] /* 打开的sub-menu项 */,
      keyPath: [],
      menu,
    }
  },
  computed: {
    // 获取有权限的路由
    routes() {
      return this.$router.options.routes
    },
  },
  watch: {
    /* 监听路由，判断当前路由来动态显示对应的菜单项 */
    $route: {
      handler(newRoute, oldRoute) {
        // console.log(newRoute, oldRoute)
        this.selectedKeys = newRoute.meta.hidden
          ? [newRoute.meta._path]
          : [newRoute.path]
        this.openKeys = [newRoute.meta.fPath]
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    },
    menuItemChick({ keyPath }) {
      // console.log(keyPath)
      /* 发送自定义事件，告诉layout现在处于哪个sub-menu */
      this.keyPath = keyPath
      this.selectedKeys = [keyPath[0]]
      this.openKeys = [keyPath[1]]
      this.$emit('submenuNumber', keyPath[1])
    },
    openChange(openKeys) {
      console.log(openKeys)
      this.openKeys = openKeys
    },
  },
  created() {
    // console.log(menu, '路由菜单')
  },
}
</script>

<style lang="less" scoped>
.menus {
  width: 95%;
  margin: 0 auto;
}

// 设置子菜单样式
/deep/ .ant-menu-submenu-active {
  // color: #151515 !important;
}
// 去掉右边框
.ant-menu-inline {
  border: none;
}
// 设置子菜单展开样式
/deep/ .ant-menu-submenu-title {
  color: #151515 !important;
}

// a链接样式
.ant-menu-item a {
  color: #999999;
}
.ant-menu-item a:hover {
  color: #151515;
}
// 选中菜单状态
/deep/ .ant-menu-item-selected {
  position: relative;

  // color: white !important;

  background-color: #1890ff !important;
  border-radius: 3px;
  a {
    color: white !important;
  }
}
</style>
